import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../../css/CardFlexStyle";
import { Link } from "gatsby";
import App from "../app";
import Taggar from '../../components/taggar'
import getLang from "../../components/scripts/getLang";
import CheckForHighlight from "../../components/scripts/checkForHightlight";
import Searchbar from '../../components/searchbar'
import Hero from '../../components/hero'

import { EventQuery } from '../../components/scripts/graphQlQuerys/eventQuery' 

const Konferens = () => {
  let [english] = useGlobal("English");
  let heroData = getLang(english, EventQuery().allContentfulEventSida);
  const [SearchKeyword, setSearchKeyword] = useState(' ');
  let { seoTitel, seoDescription, Synligataggar, introText } = heroData[0].node
  let heroTema = null;

    useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "Conferens", newURL);
    }
  });

  if(EventQuery('Konferens').allContentfulEventTeman.edges.length !== 0 & EventQuery('Konferens').allContentfulEventTeman.edges.length !== undefined){
    heroTema = getLang(english, EventQuery('Konferens').allContentfulEventTeman)
  }


  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introText} heroTema={heroTema} data={heroData} img={'img1'} />
      <Searchbar SearchKeyword={SearchKeyword} setSearchKeyword={setSearchKeyword} english={english} />
      <Taggar mainPage="lokal" subPage="konferens" data={Synligataggar} />
      <ResturantGraphql SearchKeyword={SearchKeyword} data={EventQuery().allContentfulLokaler} english={english}/>
    </App>
  );
};

export default Konferens;


const ResturantGraphql = ({ SearchKeyword, english }) => {
  const pageType = "konferens"
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let regex2 = new RegExp("Konferens", "gi");
  let lokal = getLang(english, EventQuery().allContentfulLokaler)
  lokal = CheckForHighlight(pageType, lokal)
    .filter(edge => {
      let newString = edge.node.skord.toString();
      let passarfor = edge.node.Passarfr.toString();

      return newString.match(regex) && passarfor.match(regex2);
    })
    .map((item, index) => {
      let orderOne = "";
      if(item.node.highlight){
        orderOne = "orderOne"
      }
      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/event/venues/${item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={item.node.karusellBilder[0].fluid}
            key={index}
          >
            <Styled.FlexH1>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
              <p>{item.node.huvudbyggnad.toUpperCase()}</p>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{lokal}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};
