
import { graphql, useStaticQuery } from "gatsby";

export const EventQuery = tema => {
  let query = useStaticQuery(
    graphql`
    query {
      allContentfulEventTeman{
        edges{
          node{
            node_locale
            heroRubrik
            heroBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            heroUnderRubrik{
              heroUnderRubrik
            }
            tematGllerFr
          }
        }
      }
      allContentfulEventSida {
        edges {
          node {
            node_locale
            seoTitel
            seoDescription
            heroRubrik
            Synligataggar
            introText {
              childContentfulRichText {
                html
              }
            }
            heroUnderRubrik {
              heroUnderRubrik
            }
            heroBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
              file {
                contentType
                url
              }
            }
          }
        }
      }
      allContentfulLokaler(sort: { fields: [namn], order: ASC }) {
        edges {
          node {
            updatedAt(formatString: "YY-MM/DD  h:mm:ss")
            node_locale
            heroLokal
            namn
            huvudbyggnad
            highlightadPSida
            skord
            slug
            Passarfr
            karusellBilder {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      allContentfulEventEnheter(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            updatedAt(formatString: "YY-MM/DD  h:mm:ss")
            node_locale
            slug
            namn
            order
            skord
            huvudBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    
    `
  );

  const data = {
    allContentfulEventTeman: {
      edges: query.allContentfulEventTeman.edges.filter(
        i => i.node.tematGllerFr[0] === tema
      )
    },
    allContentfulEventSida: query.allContentfulEventSida,
    allContentfulLokaler: query.allContentfulLokaler,
    allContentfulEventEnheter:query.allContentfulEventEnheter

  };

  return data;
};
